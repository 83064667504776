var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" 배너 "),_c('v-spacer'),_c('Breadcrumbs')],1),_c('v-card-title',{staticClass:"pt-0"},[_c('v-tabs',{attrs:{"color":"gray","background-color":"transparent","height":"32"}},[_c('v-tab',{staticClass:"mr-4 text-h6",attrs:{"to":"/banner/hakhak","exact-path":""}},[_vm._v("학학이 탭")]),_c('v-tab',{staticClass:"mr-4 text-h6",attrs:{"to":"/banner/home"}},[_vm._v("연구소 탭")]),_c('v-tab',{staticClass:"mr-4 text-h6",attrs:{"to":"/banner/reply"}},[_vm._v("답변 광고")]),_c('v-tab',{staticClass:"mr-4 text-h6",attrs:{"to":"/banner/main"}},[_vm._v("초기 팝업")])],1),_c('v-spacer'),_c('v-btn',{attrs:{"outlined":""},on:{"click":function($event){return _vm.to(_vm.type)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" 등록 ")],1)],1),_c('v-card',{attrs:{"flat":"","outlined":""}},[_c('v-data-table',{attrs:{"show-select":"","headers":_vm.headers,"items":_vm.list},on:{"click:row":function (item) { return _vm.$router.push(((_vm.$route.path) + "/" + (item.id))); }},scopedSlots:_vm._u([(_vm.changeIndex)?{key:"body",fn:function(){return [_c('draggable',{attrs:{"tag":"tbody"},model:{value:(_vm.list),callback:function ($$v) {_vm.list=$$v},expression:"list"}},_vm._l((_vm.list),function(item,index){return _c('tr',{key:index},[_c('td',[_c('v-icon',[_vm._v("mdi-menu")])],1),_c('td',[_vm._v(_vm._s(item.id))]),_c('td',[_vm._v(_vm._s(new Date(item.publishedAt) > new Date() ? '노출예약' : item.publishedAt ? '노출' : '비노출'))]),_c('td',[_vm._v(_vm._s(item.copyright))]),_c('td',[_vm._v(_vm._s(item.title))]),_c('td',[_c('img',{attrs:{"width":"100%","src":item.banner}})]),_c('td',[_vm._v(_vm._s(_vm.preview(item.destination, 50)))]),_c('td',[_vm._v(_vm._s(_vm.day(item.publishedAt)))])])}),0)]},proxy:true}:null,{key:"item.published",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(new Date(item.publishedAt) > new Date() ? '노출예약' : item.publishedAt ? '노출' : '비노출')+" ")]}},{key:"item.banner",fn:function(ref){
var item = ref.item;
return [_c('img',{attrs:{"width":"100%","src":item.banner}})]}},{key:"item.image",fn:function(ref){
var item = ref.item;
return [_c('img',{attrs:{"width":"100%","src":item.image}})]}},{key:"item.destination",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.preview(item.destination, 50))+" ")]}},{key:"item.publishedAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.day(item.publishedAt))+" ")]}},{key:"footer",fn:function(){return [_c('v-divider'),_c('v-container',{staticClass:"row",attrs:{"fluid":"","no-gutters":""}},[_c('v-btn',{staticClass:"mr-3",attrs:{"icon":""},on:{"click":_vm.deleteBanner}},[_c('v-icon',[_vm._v("mdi-trash-can-outline")])],1),_c('v-spacer'),(_vm.type !== 'popup')?[(_vm.changeIndex)?_c('v-btn',{staticClass:"mr-3",attrs:{"color":"red","dark":""},on:{"click":_vm.cancelOrder}},[_vm._v(" 취소 ")]):_vm._e(),_c('v-btn',{attrs:{"color":_vm.changeIndex ? 'green' : null,"dark":""},on:{"click":function($event){_vm.changeIndex ? _vm.setOrder() : (_vm.changeIndex = !_vm.changeIndex)}}},[_vm._v(" "+_vm._s(_vm.changeIndex ? '완료' : '순서 변경하기')+" ")])]:_vm._e()],2)]},proxy:true}],null,true),model:{value:(_vm.select),callback:function ($$v) {_vm.select=$$v},expression:"select"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }