<template>
  <v-container fluid>
    <v-card-title class="text-h5">
      배너
      <v-spacer />
      <Breadcrumbs />
    </v-card-title>

    <v-card-title class="pt-0">
      <v-tabs color="gray" background-color="transparent" height="32">
        <v-tab class="mr-4 text-h6" to="/banner/hakhak" exact-path>학학이 탭</v-tab>
        <v-tab class="mr-4 text-h6" to="/banner/home">연구소 탭</v-tab>
        <v-tab class="mr-4 text-h6" to="/banner/reply">답변 광고</v-tab>
        <v-tab class="mr-4 text-h6" to="/banner/main">초기 팝업</v-tab>
      </v-tabs>
      <v-spacer />
      <v-btn outlined @click="to(type)">
        <v-icon left>mdi-plus</v-icon>
        등록
      </v-btn>
    </v-card-title>

    <v-card flat outlined>
      <v-data-table v-model="select" show-select :headers="headers" :items="list" @click:row="(item) => $router.push(`${$route.path}/${item.id}`)">
        <template v-if="changeIndex" v-slot:body>
          <draggable v-model="list" tag="tbody">
            <tr v-for="(item, index) in list" :key="index">
              <td><v-icon>mdi-menu</v-icon></td>
              <td>{{ item.id }}</td>
              <td>{{ new Date(item.publishedAt) > new Date() ? '노출예약' : item.publishedAt ? '노출' : '비노출' }}</td>
              <td>{{ item.copyright }}</td>
              <td>{{ item.title }}</td>
              <td><img width="100%" :src="item.banner" /></td>
              <td>{{ preview(item.destination, 50) }}</td>
              <td>{{ day(item.publishedAt) }}</td>
            </tr>
          </draggable>
        </template>

        <template v-slot:item.published="{ item }">
          {{ new Date(item.publishedAt) > new Date() ? '노출예약' : item.publishedAt ? '노출' : '비노출' }}
        </template>

        <template v-slot:item.banner="{ item }">
          <img width="100%" :src="item.banner" />
        </template>

        <template v-slot:item.image="{ item }">
          <img width="100%" :src="item.image" />
        </template>

        <template v-slot:item.destination="{ item }">
          {{ preview(item.destination, 50) }}
        </template>

        <template v-slot:item.publishedAt="{ item }">
          {{ day(item.publishedAt) }}
        </template>

        <template v-slot:footer>
          <v-divider />
          <v-container fluid class="row" no-gutters>
            <v-btn icon class="mr-3" @click="deleteBanner">
              <v-icon>mdi-trash-can-outline</v-icon>
            </v-btn>

            <v-spacer />

            <template v-if="type !== 'popup'">
              <v-btn v-if="changeIndex" class="mr-3" color="red" dark @click="cancelOrder">
                취소
              </v-btn>
              <v-btn :color="changeIndex ? 'green' : null" dark @click="changeIndex ? setOrder() : (changeIndex = !changeIndex)">
                {{ changeIndex ? '완료' : '순서 변경하기' }}
              </v-btn>
            </template>
          </v-container>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import { computed, defineComponent, onBeforeMount, reactive, toRefs, watch } from '@vue/composition-api'
import { day, preview } from '@/helper'
import draggable from 'vuedraggable'
import Breadcrumbs from '@/components/Breadcrumbs.vue'

export default defineComponent({
  components: { draggable, Breadcrumbs },

  props: { type: String },

  setup(props, { root }) {
    const state = reactive({
      select: [],
      list: [],
      changeIndex: false,
      popupHeaders: [
        { text: 'ID', value: 'id' },
        { text: '노출상태', value: 'published' },
        { text: '메인 문구', value: 'copyright' },
        { text: '이미지', value: 'image', sortable: false, width: '200' },
        { text: '링크', value: 'destination' },
        { text: '노출/예약 일시', value: 'publishedAt' },
      ],
      bannerHeaders: [
        { text: 'ID', value: 'id' },
        { text: '노출상태', value: 'published' },
        { text: '메인 문구', value: 'copyright' },
        { text: '서브 문구', value: 'title' },
        { text: '이미지', value: 'banner', sortable: false, width: '200' },
        { text: '링크', value: 'destination' },
        { text: '노출/예약 일시', value: 'publishedAt' },
      ],
    })

    const headers = computed(() => {
      if (props.type === 'popup') return state.popupHeaders
      return state.bannerHeaders
    })

    const setOrder = async () => {
      let count = 1
      for (const i in state.list) {
        await root.$store.dispatch('banner/updateBanner', {
          id: Number(state.list[i].id),
          bannerType: props.type,
          order: count++,
        })
      }
      await getListBanner()
      state.changeIndex = false
    }

    const cancelOrder = async () => {
      await getListBanner()
      state.changeIndex = false
    }

    const getList = async () => {
      state.select = []

      if (props.type === 'popup') getListPopup()
      else getListBanner()
    }

    const getListBanner = async () => {
      state.list = await root.$store.dispatch('banner/getListBanner', {
        type: props.type,
      })

      state.list.sort((a, b) => {
        return a.order - b.order
      })
    }

    const getListPopup = async () => {
      state.list = await root.$store.dispatch('popup/getListPopup')
    }

    const to = (type) => {
      root.$router.push(`/banner/${type}/create`)
    }

    const deleteBanner = async () => {
      try {
        if (!confirm('배너를 삭제합니다.')) return

        for (const e of state.select) {
          await root.$store.dispatch('banner/deleteBanner', {
            id: Number(e.id),
          })
        }

        state.select = []

        getList()
      } catch (err) {
        console.error(err)
        alert(err)
      }
    }

    onBeforeMount(() => {
      getList()
    })

    watch(
      () => props.type,
      () => {
        getList()
      },
    )

    return {
      ...toRefs(state),
      setOrder,
      cancelOrder,
      deleteBanner,
      to,
      headers,
      day,
      preview,
    }
  },
})
</script>

<style scoped>
.v-tabs {
  width: fit-content;
}

.v-tab {
  min-width: unset;
  padding: 0;
}
</style>
